import React, { useEffect, useRef, useState } from 'react';
import { graphql } from "gatsby"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import parse from 'html-react-parser';
import Layout from "../../components/layout";
import Seo from "../../components/seo";

//sections
import EletiveHeroSection from '../sections/caseStudies/eletive/EletiveHeroSection';
import ChallengesSection from '../sections/caseStudies/ChallengesSection';
import EletiveSection1 from '../sections/caseStudies/eletive/EletiveSection1';
import EletiveSection2 from '../sections/caseStudies/eletive/EletiveSection2';
import EletiveSection3 from '../sections/caseStudies/eletive/EletiveSection3';
import EletiveSection4 from '../sections/caseStudies/eletive/EletiveSection4';
import EletiveSection5 from '../sections/caseStudies/eletive/EletiveSection5';

const Eletive = ({ data }) => {
  const mainData = data.allWpPage.nodes[0].caseStudyEletiveACF;
  console.log(mainData);
  const sectionRef = useRef();
  const [bgTransition, setBgTransition] = useState('');
  return (
    <Layout>
      <Seo title="Eletive" />
      <div className={"eletive-case-study page-wrapper " + bgTransition} id="eletive-casestudy-page-wrapper" ref={sectionRef}>
        <EletiveHeroSection 
            preTitle={parse(mainData.eletiveHeroSectionPretitle)}
            mainTitle={parse(mainData.eletiveHeroSectionTitle)}
            image1x={
              {
                sourceUrl: mainData.eletiveHeroSectionImage1x.sourceUrl,
                altText: mainData.eletiveHeroSectionImage1x.altText,
                width: mainData.eletiveHeroSectionImage1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.eletiveHeroSectionImage2x.sourceUrl,
                altText: mainData.eletiveHeroSectionImage2x.altText,
                width: mainData.eletiveHeroSectionImage2x.width,
              }
            }
            mobileImage={
              {
                sourceUrl: mainData.eletiveHeroSectionMobileImage.sourceUrl,
                altText: mainData.eletiveHeroSectionMobileImage.altText,
                width: mainData.eletiveHeroSectionMobileImage.width,
              }
            }
        />
        <ChallengesSection 
            companyInfoList={mainData.eletiveChallengeSectionCompanyInfoList}
            challengesList={mainData.eletiveChallengeSectionChallangesList}
        />
        <EletiveSection1 
            mainTitle={parse(mainData.eletiveSection1Title)}
            mainDescription={parse(mainData.eletiveSection1Description)}
            image1x={
              {
                sourceUrl: mainData.eletiveSection1Image1x.sourceUrl,
                altText: mainData.eletiveSection1Image1x.altText,
                width: mainData.eletiveSection1Image1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.eletiveSection1Image2x.sourceUrl,
                altText: mainData.eletiveSection1Image2x.altText,
                width: mainData.eletiveSection1Image2x.width,
              }
            }
        />
        <EletiveSection2 
            mainTitle={parse(mainData.eletiveSection2Title)}
            mainDescription={parse(mainData.eletiveSection2Description)}
            imageslist={mainData.eletiveSection2ImageList}
        />
        <EletiveSection3 
            mainTitle={parse(mainData.eletiveSection3Title)}
            mainDescription={parse(mainData.eletiveSection3Description)}
            image11x={
              {
                sourceUrl: mainData.eletiveSection3Image11x .sourceUrl,
                altText: mainData.eletiveSection3Image11x .altText,
                width: mainData.eletiveSection3Image11x .width,
              }
            }
            image12x={
              {
                sourceUrl: mainData.eletiveSection3Image12x.sourceUrl,
                altText: mainData.eletiveSection3Image12x.altText,
                width: mainData.eletiveSection3Image12x.width,
              }
            }
            image21x={
              {
                sourceUrl: mainData.eletiveSection3Image21x.sourceUrl,
                altText: mainData.eletiveSection3Image21x.altText,
                width: mainData.eletiveSection3Image21x.width,
              }
            }
            image22x={
              {
                sourceUrl: mainData.eletiveSection3Image22x.sourceUrl,
                altText: mainData.eletiveSection3Image22x.altText,
                width: mainData.eletiveSection3Image22x.width,
              }
            }
        />
        <EletiveSection4 
            mainTitle={parse(mainData.eletiveSection4Title)}
            mainDescription={parse(mainData.eletiveSection4Description)}
            subTitle={parse(mainData.eletiveSection4SubTitle)}
            imageslist={mainData.eletiveSection4ImageList}
            imageslist2={mainData.eletiveSection4ImageList2}
        />
        <EletiveSection5 
            image1x={
              {
                sourceUrl: mainData.eletiveSection5Image1x.sourceUrl,
                altText: mainData.eletiveSection5Image1x.altText,
                width: mainData.eletiveSection5Image1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.eletiveSection5Image2x.sourceUrl,
                altText: mainData.eletiveSection5Image2x.altText,
                width: mainData.eletiveSection5Image2x.width,
              }
            }
        />
      </div>
    </Layout>
  )
}



export const EletiveQuery = graphql`

{
  allWpPage(filter: {slug: {eq: "eletive"}}) {
      nodes {
        caseStudyEletiveACF {
            eletiveHeroSectionPretitle
            eletiveHeroSectionTitle
            eletiveHeroSectionImage1x {
              altText
              sourceUrl
              width
            }
            eletiveHeroSectionImage2x {
              altText
              sourceUrl
              width
            }
            eletiveHeroSectionMobileImage {
                altText
                sourceUrl
                width
            }
            eletiveChallengeSectionCompanyInfoList {
              title
              description
            }
            eletiveChallengeSectionChallangesList {
              title
              description
            }

            eletiveSection1Title
            eletiveSection1Description
            eletiveSection1Image1x {
              altText
              sourceUrl
              width
            }
            eletiveSection1Image2x {
              altText
              sourceUrl
              width
            }
            eletiveSection2Title
            eletiveSection2Description
            eletiveSection2ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            eletiveSection3Title
            eletiveSection3Description
            eletiveSection3Image11x {
              altText
              sourceUrl
              width
            }
            eletiveSection3Image12x {
              altText
              sourceUrl
              width
            }
            eletiveSection3Image21x {
              altText
              sourceUrl
              width
            }
            eletiveSection3Image22x {
              altText
              sourceUrl
              width
            }

            eletiveSection4Title
            eletiveSection4Description
            eletiveSection4SubTitle
            eletiveSection4ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            eletiveSection4ImageList2 {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            eletiveSection5Image1x {
              altText
              sourceUrl
              width
            }
            eletiveSection5Image2x {
              altText
              sourceUrl
              width
            }
          }
      }
    }
}

`

export default Eletive